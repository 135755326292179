import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Divider, Container, Text, Box, Stack, Flex, Image} from '@chakra-ui/react'

function ListFiles() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const truncateAbstract = (text) => {
    return text.length > 60 ? text.slice(0, 60) + '...' : text;
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          'https://www.googleapis.com/storage/v1/b/albgarci/o', // URL para la API de GCS
          {
            params: {
              prefix: '', // Si deseas limitar los archivos con un prefijo específico
              delimiter: '/',
            },
          }
        );
        setFiles(response.data.items || []);
      } catch (error) {
        setError('Error al obtener archivos');
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (

    <div>
    <Box h={20} />
	<Container maxWidth='100ch'> 
		<Text fontWeight='400' color='custom.100'>
			<Text fontWeight='600' as='span' color='custom.300'>Blog posts </Text>
		</Text>
	<Divider mb={5}/>

    <Stack spacing={8}>
    {files.filter(el => el.name.includes(".json"))
      .sort((a,b) => new Date(b.metadata.published) - new Date(a.metadata.published))
      .map((file) => (
      <Link
        key={file.name}
        to={`/post/${file.name.replace('.json', '')}`}
        style={{ textDecoration: 'none' }}
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align="center"
          justify="space-between"
          p={14}
          borderRadius="md"
          bg="#1a202c"
          _hover={{ bg: '#2d3748' }}
          cursor="pointer"
          boxShadow="md"
          
     			   
        >
          <Box flex="1" >
            <Text fontSize="3xl" fontWeight='600' color="#ccd6f6">
              {file.metadata?.title}
            </Text>
            <Text fontSize="xl" color="custom.100" mt={2}>
              {file.metadata?.abstract}
            </Text>
            <Text fontSize="sm" color="custom.100" mt={2}>
              {file.metadata?.published}
            </Text>
          </Box>

          <Box mt={{ base: 4, md: 0 }} flex="0 0 150px">
            <Image
              src={`https://storage.googleapis.com/albgarci/${file.name.replace('.json', '.webp')}`}
              alt={file.metadata?.title}
              borderRadius="md"
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Link>
    ))}
  </Stack> 
  </Container>
  <Box h={20} />
    </div>
  );
}

export default ListFiles;
